import {API_PREFIX} from "@utils/config";
import axios from "axios";

const URL: string = `${API_PREFIX}`
axios.defaults.withCredentials = true;

class PublicService {
    async getAllEmailDomains() {
        return await axios.get(`${URL}/get-all-email-domains`)
    }

    async getAllCardTypes() {
        return await axios.get(`${URL}/get-all-card-types`)
    }

    async getAllLoanPackages() {
        return await axios.get(`${URL}/get-all-loan-packages`)
    }

    async getAllCreditCard() {
        return await axios.get(`${URL}/get-all-credit-cards`)
    }

    async getAllCreditCardByPartner(partner: string) {
        return await axios.get(`${URL}/product/list-product`, {
            params: {partner}
        });
    }

    async getAllLoanByPartner(partner: string) {
        return await axios.get(`${URL}/product/list-product`, {
            params: {partner}
        });
    }

    async applyRegister(step: string, type: string, lead: any) {
        let uuid = sessionStorage.getItem('lead_id');
        if (!uuid) {
            uuid = crypto.randomUUID();
            sessionStorage.setItem('lead_id', uuid);
        }

        lead['token'] = sessionStorage.getItem('token');
        lead['ip'] = sessionStorage.getItem('ip');
        lead['link'] = window.location.href;
        lead['uuid'] = uuid;

        const url = `${URL}/apply-register?step=${step}&type=${type}`;
        return await axios.post(url, lead);
    }

    // Thêm hàm verifyEmail
    async verifyEmail(emailData: { email: string }) {
        // Sử dụng axios.get với query parameter cho email
        return await axios.get(`${URL}/email/email-validations`, {
            params: {
                email: emailData.email
            }
        });
    }

    // Lưu sản phẩm người dùng chọn
    async saveLeadProduct(productData: any) {
        return await axios.post(`${URL}/save-product`, productData)
    }

    //Setup Css
    async getPartnerCssByProductPartner(partner: string) {
        return await axios.get(`${URL}/products/partner-css`, {
            params: {partner}
        });
    }

    // Tìm tất cả Category
    async findAllCategory() {
        return await axios.get(`${URL}/category/find-all-category`);
    }

    // Tìm Category theo id
    async findCategoryById(id: number) {
        return await axios.get(`${URL}/category/find-category-by-id/${id}`);
    }

    // Tìm tất cả Blog
    async findAllBlog() {
        return await axios.get(`${URL}/blog/find-all-blog`);
    }

    //Tìm Blog theo id
    async findBlogById(id: number) {
        return await axios.get(`${URL}/blog/find-blog-by-id/${id}`);
    }

    //Tìm Blog theo Category
    async findByCategoryAcronymLinkv2(acronymLink: string) {
        return await axios.get(`${URL}/blog/find-blog-by-category/${acronymLink}`);
    }

}

const publicService = new PublicService();
export default publicService;
