import "@assets/css/material-kit.css";
import "@assets/css/nucleo-icons.css";
import "@assets/css/nucleo-svg.css";
import {images} from '@assets/images';
import React, {useEffect} from 'react';
import "./AppHeader1.scss";
import {useState} from 'react';
import publicService from "../../../service/PublicService";

const AppHeader = () => {

    const [showLink, setShowLink] = useState(false);
    const [partnersLogo, setPartnersLogo] = useState<string | JSX.Element>(
        <img src={images.gocard.logoHeader} alt="GoCard Logo"/>
    );
    const [navbarClass, setNavbarClass] = useState<React.CSSProperties>({});
    // const [navbarIcon, setNavbarIcon] = useState('uk-navbar-item');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const partner = urlParams.get("partner");

        const fetchPartnerCss = async (partner: string | null) => {
            if (partner) {
                try {
                    const respStr = sessionStorage.getItem(partner)
                    let resp;
                    if(respStr) {
                        resp = JSON.parse(respStr);
                    }else{
                        resp = await publicService.getPartnerCssByProductPartner(partner);
                        sessionStorage.setItem(partner,JSON.stringify(resp));
                    }

                    const data = resp.data;

                    const logoStyle = {
                        width: partner === 'tpbank' ? '238px' : 'auto',
                        height: partner === 'tpbank' ? '34px' : 'auto'
                    }
                    setPartnersLogo(
                        <div style={{backgroundColor: data.colorHeader}}>
                            <img src={data.logoHeader} alt="Partner Logo" style={logoStyle}/>
                        </div>
                    );
                    setNavbarClass({
                        backgroundColor: data.colorHeader || '#D9FC00'
                    });
                    // setNavbarIcon(data.colorHeader);
                } catch (err) {
                    console.log('err', err);
                }
            }
        }

        fetchPartnerCss(partner);

    }, [])

    const handleToggle = () => {
        setShowLink(!showLink);
    }

    return (
        <>
            <header className="header uk-sticky">
                <div className={`header__bottom`}>
                    <div className="container p-0">
                        <nav className="uk-navbar-container">
                            <div className="uk-navbar-center">
                                <a href="" className="uk-navbar-item" style={navbarClass}>
                                    {partnersLogo}
                                </a>
                            </div>

                            <div className="uk-navbar-left" style={navbarClass}>
                                <div className="menu-main-menu-container">
                                </div>
                                <a className="header__bottom__menuBtn uk-navbar-toggle uk-hidden@m uk-light"
                                   href="#offcanvasMenumobile" data-bs-toggle="offcanvas"
                                   aria-controls="offcanvasMenumobile">
                                    <span className="uk-icon uk-navbar-toggle-icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20"
                                             xmlns="http://www.w3.org/2000/svg">
                                        <rect className="line-1" y="3" width="20" height="2"/>
                                        <rect className="line-2" y="9" width="20" height="2"/>
                                        <rect className="line-3" y="9" width="20" height="2"/>
                                        <rect className="line-4" y="15" width="20" height="2"/>
                                        </svg>
                                    </span>
                                    <span className="uk-margin-small-left"></span>
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="offcanvas offcanvas-start" id="offcanvasMenumobile"
                     aria-labelledby="offcanvasMenumobileLabel" aria-hidden="true">
                    <div className="offcanvas-header">
                        <a href=" " id="offcanvasMenumobileLogo">
                            {partnersLogo}
                        </a>
                        <button type="button" className="btn-close-custom text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"/>
                    </div>

                    <div className="offcanvas-body">
                        <ul className="main-nav-mb list-group list-group-flush">
                            <li className="menu-item list-group-item"><a href="/gioi-thieu">Giới thiệu</a></li>
                            <li className="menu-item list-group-item">
                                <a href="/dieu-khoan-su-dung">Điều khoản sử dụng</a></li>
                            <li className="menu-item list-group-item">
                                <a href="/blog">Blogs</a></li>
                            <li className="menu-item list-group-item">
                                <a onClick={handleToggle} style={{cursor: 'pointer'}}>Thông tin sản phẩm</a>
                                {showLink && (
                                    <ul className="sub-menu">
                                        <li><a href="/loan">Vay tiêu dùng</a></li>
                                        <li><a href="/card">Thẻ tín dụng</a></li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </>
    )
}

export default React.memo(AppHeader);
