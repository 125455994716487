import React, {useEffect, useState} from 'react';
import './SuccessView.scss';
import {images} from '@assets/images';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';
import publicService from "../../../service/PublicService";
import {useHistory} from "react-router-dom";

interface LoanData {
    term: number;
    loanLimit: number;
}

const getAllLoanPackages = async () => {
    try {
        const resp = await publicService.getAllLoanPackages();
        return resp.data;
    } catch (error) {
        console.error("Error", error);
        return [];
    }
}

const SuccessView = () => {
    const [loanData, setLoanData] = useState<LoanData | null>(null);
    const [loanPackage, setLoanPackage] = useState<any[]>([]);
    const history = useHistory();
    const [show, setShow] = useState<string>('block');

    useEffect(() => {
        document.title = "Gocard | Đăng ký vay thành công"
    }, []);

    useEffect(() => {
        const storedCardData = sessionStorage.getItem('loanData');
        const selectedCode = sessionStorage.getItem('selectedCode');
        console.log(selectedCode)
        if(selectedCode !== 'tnex-finance' && selectedCode){
            setShow('none');
        }
        if (storedCardData) {
            setLoanData(JSON.parse(storedCardData));
        }

        const fetchData = async () => {
            const data = await getAllLoanPackages();
            const arr = []
            for(let el of data){
                if(el.code != selectedCode && selectedCode){
                    arr.push(el)
                }else{
                    if(el.code != 'tnex-finance'){
                        arr.push(el)
                    }
                }
            }
            setLoanPackage(arr);
        }

        fetchData();

        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        window.location.href = '/';
        sessionStorage.clear();
    }

    const handleCreateLoanPackage = (loanPackageItem: any) => {
        sessionStorage.setItem('selectedCode', loanPackageItem.code);
        if(loanPackageItem.navigateLink){
            window.location.href = (loanPackageItem.navigateLink);
        }else{
            history.push({pathname: '/',})
        }
    }

    const handleLoanDetail = (loanPackageItem: any) => {
        history.push({
            pathname: '/loan-detail',
            state: {loanItem: loanPackageItem}
        });
    }

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const formatLoanLimit = (loanLimit: number) => {
        const loanLimitInMillions = loanLimit / 1000000;
        return formatNumberCustom(loanLimitInMillions);
    };

    return (
        <>
            <div className='container'>
                <div className='img'>
                    <img className='img-success' src={images.gocard.successImage} alt="success-image"/>
                </div>
                <span className='label-success svn-bold'>Đăng ký thành công</span>
                {loanData && (
                    <>
                        <span style={{fontSize: '16px', padding: '30px 0 10px 0', fontFamily: 'SVN-Bold'}}>Chi tiết khoản vay</span>
                        <div className="loan-tnex">
                            <div className="loan-tnex-detail">
                                <ul>
                                    <li>
                                        <span>
                                            <img src={images.gocard.loanTimeIcon} alt="loan-time-icon"/>
                                            Thời gian vay
                                        </span>
                                        <p>{loanData.term} tháng</p>
                                    </li>
                                    <li>
                                        <span>
                                            <img src={images.gocard.loanLimitIcon} alt="loan-limit-icon"/>
                                            Hạn mức vay
                                        </span>
                                        <p>{formatLoanLimit(loanData.loanLimit)} triệu đồng</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}
                <p className='txt-success svn-font' style={{display:show}}>
                    Để nhận khoản vay ngay, vui lòng làm theo <a href="/blog-detail/16">hướng dẫn tại đây</a>.
                    Hoặc nhân viên tư vấn sẽ liên hệ với bạn trong vòng 24h.
                </p>
                <div className='btn-back'>
                    <button onClick={handleClick}>Quay về trang chủ</button>
                </div>

                {loanPackage.length > 0 && (
                    <div className="loan-packages">
                        <ul>
                            {loanPackage
                                .filter((loan) => {
                                    // Lấy selectedLoanPackage từ sessionStorage và kiểm tra null
                                    const selectedLoanPackageStr = sessionStorage.getItem('selectedLoanPackage');
                                    const selectedLoanPackage = selectedLoanPackageStr ? JSON.parse(selectedLoanPackageStr) : null;

                                    // Nếu không có selectedLoanPackage, không cần lọc
                                    if (!selectedLoanPackage) return true;

                                    // Lấy priority và name từ selectedLoanPackage
                                    const { priority, name } = selectedLoanPackage;

                                    // Kiểm tra nếu loan có trùng cả priority và name, thì bỏ qua không hiển thị
                                    return !(loan.priority === priority && loan.name === name);
                                })
                                .map((loan, index) => (
                                    <React.Fragment key={index}>
                                        {/* Dòng thông báo chỉ hiển thị nếu có ít nhất 1 khoản vay */}
                                        {index === 0 && (
                                            <span style={{ fontFamily: 'SVN-Bold' }}>Tham khảo các sản phẩm khác của chúng tôi</span>
                                        )}
                                        <li className="loan-package">
                                            <div key={loan.id} className='loan-package-item-container'>
                                                <div className='loan-package-item'>
                                                    <div className="img-loan-package-item">
                                                        <img src={loan.iconUrl} alt="loan" />
                                                    </div>
                                                    <span>Lãi suất hấp dẫn chỉ từ {loan.interestRate}%/năm</span>
                                                    <div className='text-loan-package-item'>
                                                        <p>Lãi suất<br /> <b>từ {loan.interestRate}%</b> <br />{loan.interestRateUnit}</p>
                                                        <p>Ưu đãi<br /> <b>{loan.term}</b> <br />Tháng</p>
                                                    </div>
                                                    <button
                                                        onClick={() => handleCreateLoanPackage(loan)}
                                                    >Đăng ký ngay
                                                    </button>
                                                    <a className='see-detail-loan-package-item'
                                                       onClick={() => handleLoanDetail(loan)}
                                                    >Xem chi tiết</a>
                                                </div>
                                            </div>
                                        </li>
                                    </React.Fragment>
                                ))}
                        </ul>
                    </div>
                )}
                <ScrollToTopButton/>
            </div>
        </>
    );
}

export default React.memo(SuccessView);
