import { Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Turnstile from 'react-turnstile'
import publicService from 'src/app/service/PublicService'
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton'
import LicensePageView from '../LicensePageView/LicensePageView'

const RegisterCardPageView: React.FC = () => {
  const [income, setIncome] = useState('')
  const [loading, setLoading] = useState(false)
  const [agreement, setAgreement] = useState<string | null>(null)
  const [checkCaptcha, setCheckCaptcha] = useState(true)
  const [warningIncome, setWarningIncome] = useState('')
  const [warningLimit, setWarningLimit] = useState('')
  const [cardType] = useState('Tất cả')
  const [limit, setLimit] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const history = useHistory()

  useEffect(() => {}, [])

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.value)
  }

  const handleSearch = async (step: string, type: string) => {
    if (agreement === 'agree') {
      const cardInfo = {
        creditCardType: cardType,
        monthlyIncome: income,
        creditLimit: limit,
      }
      sessionStorage.setItem('cardInfo', JSON.stringify(cardInfo))
      setLoading(true)
      try {
        const resp = await publicService.applyRegister(step, type, cardInfo)
        if (resp.status === 200) {
          history.push('/contact-info/credit-card')
        } else {
          console.log(resp)
        }
      } catch (error) {
        console.error('Lỗi khi đăng ký thẻ tín dụng:', error)
      } finally {
        setLoading(false)
      }
    } else {
      alert('Bạn phải đồng ý với Điều khoản sử dụng để tiếp tục.')
    }
  }

  const verifyCaptcha = (token: string) => {
    sessionStorage.setItem('token', token)
    setCheckCaptcha(false)
  }

  const handleIncomeChange = (e: any) => {
    const value = e.target.value
    // if (value.length > 3) {
    //     return;
    // }
    if (/^\d*$/.test(value) && (value === '' || (Number(value) <= 999 && Number(value) >= 1))) {
      setIncome(value)
      setWarningIncome('')
    } else if (value !== '') {
      setWarningIncome('Tối đa 999 triệu đồng.')
    } else {
      setWarningIncome('')
    }
  }

  const handleLimitChange = (e: any) => {
    const value = e.target.value
    // if (value.length > 3) {
    //     return;
    // }
    if (/^\d*$/.test(value) && (value === '' || (Number(value) <= 999 && Number(value) >= 1))) {
      setLimit(value)
      setWarningLimit('')
    } else if (value !== '') {
      setWarningLimit('Tối đa 999 triệu đồng.')
    } else {
      setWarningLimit('')
    }
  }

  const openModal = (event: any) => {
    event.preventDefault()
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Spin spinning={loading}>
        <div className='card-container mt-3'>
          <h2 className='card-title'>LỰA CHỌN THẺ TÍN DỤNG</h2>
          <span className='text-income'>Mức thu nhập hàng tháng</span>
          <div className='input-group mb-0'>
            <input
              type='text'
              id='income'
              placeholder='Vui lòng nhập'
              max='999'
              pattern='\d*'
              value={income}
              onChange={handleIncomeChange}
            />
            <span>triệu đồng</span>
          </div>
          {warningIncome && <div className='warning-message-income'>{warningIncome}</div>}
          <span className='text-limit mt-3'>Hạn mức mong muốn</span>
          <div className='input-group'>
            <input
              type='text'
              id='limit'
              placeholder='Vui lòng nhập'
              max='999'
              pattern='\d*' // Chỉ cho phép nhập số
              value={limit}
              onChange={handleLimitChange}
            />
            <span>triệu đồng</span>
            {warningLimit && <div className='warning-message-limit'>{warningLimit}</div>}
          </div>
          <div className='terms-container'>
            <p>
              Để đăng ký và sử dụng dịch vụ từ Gocard, xin vui lòng đọc và đồng ý với
              <a href='#' onClick={openModal}>
                Điều khoản sử dụng dịch vụ
              </a>
              của chúng tôi.
              <LicensePageView isModalVisible={isModalVisible} closeModal={closeModal} />
            </p>
            <div className='terms-options'>
              <div className='form-check'>
                <div>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault1'
                    value='agree'
                    onChange={handleAgreementChange}
                    checked={agreement === 'agree'}
                  />
                </div>
                <label className='form-check-label' htmlFor='flexRadioDefault1'>
                  Tôi đồng ý và muốn sử dụng dịch vụ.
                </label>
              </div>
              <div className='form-check'>
                <div>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault2'
                    value='disagree'
                    onChange={handleAgreementChange}
                    checked={agreement === 'disagree'}
                    style={{ alignSelf: 'flex-start', marginTop: '0.8rem' }}
                  />
                </div>
                <label className='form-check-label' htmlFor='flexRadioDefault2' style={{ lineHeight: '14px' }}>
                  Tôi không đồng ý với toàn bộ hoặc một phần
                  <br /> trong Điều khoản dịch vụ.
                </label>
              </div>
            </div>
          </div>
          <Turnstile
            className='varify'
            sitekey='0x4AAAAAAAgoq9QLELbUz05a'
            onVerify={(token: any) => verifyCaptcha(token)}
          />
          <button
            className='search-button'
            disabled={checkCaptcha}
            onClick={() => handleSearch('dang-ky-the-tin-dung', 'credit-card')}>
            Tìm kiếm ngay
          </button>
        </div>
        <ScrollToTopButton />
      </Spin>
    </>
  )
}

export default React.memo(RegisterCardPageView)
